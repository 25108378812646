import React from 'react';
import { useEffect } from 'react';
import '../../App.css';
import Footer from '../Footer';

import logo from '../../images/logo.jpg';
import ceo from '../../images/ceo.jpg';

function Team() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return (
        <div>
            <img src={logo} className='img'/>
            <h1 className='heading'>Meet Our Team</h1>
            <div>
                <div>
                    <div>
                        <img src={ceo} className='team-img'/>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Team