import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Start Growing Your Wealth Today
        </p>
        <p className='footer-subscription-text'>
          Stay Updated
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Submit</Button>
          </form>
        </div>
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/team'>Team</Link>
            <Link to='/career'>Careers</Link>
            <Link to='/legal'>Terms of Service</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/contact'>Contact</Link>
            <Link to='/team'>Support</Link>
            <Link to='/services'>Products</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Partner</h2>
            <Link to='/contact'>Inquire</Link>
            <Link to='/invest'>Invest</Link>
            <Link to='/sponsor'>Sponsor</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Connect</h2>
            <a href='https://www.facebook.com/Sky-Data-104667588877938'>Facebook</a>
            <a href='https://www.instagram.com/capitalskydata/'>Instagram</a>
            <a href='https://www.linkedin.com/company/capitalskydata'>LinkedIn</a>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              Capital Sky Data &nbsp;
              <i class='fas fa-chart-line' />
            </Link>
          </div>
          <small class='website-rights'>Capital Sky Data LLC. © 2022</small>
          <div class='social-icons'>
            <a
              class='social-icon-link facebook'
              href='https://www.facebook.com/Sky-Data-104667588877938'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </a>
            <a
              class='social-icon-link instagram'
              href='https://www.instagram.com/capitalskydata/'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </a>
            <a
              class='social-icon-link twitter'
              href='https://www.linkedin.com/company/capitalskydata'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
