import React from 'react';
import { useEffect } from 'react';
import Typist from 'react-typist';
import Footer from '../Footer';
import './Services.css';
import '../../App.css';

import blockchain from '../../images/blockchain.png';
import sky from '../../videos/sky.mp4';
import money from '../../images/money.png';
import idea_to_money from '../../images/idea-to-money.png';
import data from '../../images/data.jpg';
import ai from '../../images/ai.png';

export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <h1 className='services'><Typist avgTypingDelay={110}>SERVICES</Typist></h1>
      <div className='background' style={{height: 'auto'}}>
        <h1 className='heading' style={{padding: '10vw'}}>Blockchain</h1>
        <img className='img' src={blockchain} />
        <p className='description'>
          With the boom in cryptocurrency, mining coins such as Ethereum(ETH) has been popular and profitable than ever before.
          Mining cryptocurrency is not only profitable by itself but also hedges against decline in value of cryptocurrency.
        </p>
        <h1 className='heading' style={{padding: '10vw'}}>Deep Value Investing</h1>
        <img className='img' src={money} style={{width: '75vw'}} />
        <p className='description'>
          By finding value that has not been realized yet, deep value investing, though it comes with increased risk, can help us realize significant gains.
        </p>
        <h1 className='heading' style={{padding: '10vw'}}>Marketing Driven Online Store</h1>
        <img className='img' src={idea_to_money} />
        <p className='description'>
          With an explosion in use of social media and social media marketing, especially after COIVD, several clever marketing strategies can boost our online store.
        </p>
        <h1 className='heading' style={{padding: '10vw'}}>Next Generation Data Analytics</h1>
        <img className='img' src={data} />
        <p className='description'>
          We can provide insightful information on publically traded companies, using webscraping for valuable data and Python for data analytics.
        </p>
        <h1 className='heading' style={{padding: '10vw'}}>A.I. Investing</h1>
        <img className='img' src={ai} />
        <p className='description'>
          With decades of historical stock data, we can run multiple models to find optimal short term trading strategies.
        </p>
      </div>
      <div className='space'></div>
      <Footer />
    </>
  );
}
