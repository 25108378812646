import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import { Button } from './Button';
import { Button2 } from './Button2';
import './HeroSection.css';

import network from '../images/network2.jpg';
import image from '../images/sky.jpg';
import austin from '../videos/austin.mp4';
let footage = 'https://s3.amazonaws.com/capitalskydata.com/network.mp4';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video loop autoPlay muted playsInline width="100%" height="100%">
        <source src={footage} type="video/mp4"/>
      </video>
      {/* <img src={image}/> */}
      <h1>Better Wealth Management</h1>
      <p>Private Asset Management and Financial Research</p>
      <div className='hero-btns'>
        <Button
        className='btns'
        buttonStyle='btn--outline'
        buttonSize='btn--large'
        >
        CONTACT
        </Button>
        <Button2
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
        >
          Learn More <i className='fas fa-info-circle' />
        </Button2>
      </div>
    </div>
  );
}

export default HeroSection;
