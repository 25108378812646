import React from 'react';
import { useEffect } from 'react';
import Typist from 'react-typist';
import '../../App.css';
import './About.css';
import Footer from '../Footer';


import ceo from '../../images/ceo.jpg';
import footage from '../../videos/sky.mp4';
import austin from '../../images/austin.jpg';

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div >
      <h1 className='about'></h1>
      <h2>&nbsp;</h2>
      <h2>&nbsp;</h2>
      <h2 className='heading'>Capital Sky Data is a privately funded investment company based in Austin, Texas</h2>

      <img src={austin} className='img'/>

      <h2 className='description-heading'>Our Mission</h2>

      <h2 className='description'>We create or increase value for our company, our customers, and our world.</h2>

      <h2 className='mission-heading'><i class="fas fa-user"></i>&nbsp;&nbsp;&nbsp;&nbsp; People first</h2>

      <h2 className='mission'>Taking not just our employees and customers but also everyone into the equation</h2>

      <h2 className='mission-heading'><i class="fas fa-coins"></i>&nbsp;&nbsp;&nbsp;&nbsp; Independnce and Decentralization</h2>

      <h2 className='mission'>Working free from other influences and empowering those who seek independence</h2>

      <h2 className='mission-heading'><i class="fas fa-tree"></i>&nbsp;&nbsp;&nbsp;&nbsp; Better quality of life and sustainability</h2>

      <h2 className='mission'>Investing in green and long term sustainability for everyone</h2>

      {/* <h2 className='title'>Founder CEO - Jake Yoon</h2>
      <img className='images' src={ceo}/> */}
      <h2 className='description' style={{paddingTop: "250px"}}>We plan on taking outside funds soon for our customers to participate in our investment strategy.</h2>
      <Footer/>
    </div>
    );
}
