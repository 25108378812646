import React from 'react';
import './Cards.css';
import CardItem from './CardItem';


import blockchain from '../images/blockchain.png';
import money from '../images/money.png';
import idea_to_money from '../images/idea-to-money.png';
import data from '../images/data.jpg';
import ai from '../images/ai.png';


function Cards() {
  return (
    <div className='cards'>
      <h1>List of Investment Types</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={blockchain}
              text='Safe and Secure Crpytocurrency Mining'
              label='Cryptocurrency'
              path='/services'
            />
            <CardItem
              src={money}
              text='Deep Value Stock Investing'
              label='Stock'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={idea_to_money}
              text='Marketing Driven Online Store'
              label='Merchandise'
              path='/services'
            />
            <CardItem
              src={data}
              text='Next Generation Data Analytics'
              label='Data Analytics'
              path='/services'
            />
            <CardItem
              src={ai}
              text='A.I. Driven Investing Strategy'
              label='Quant Trading'
              path='/services'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
