import React from 'react';
import { useEffect } from 'react';
import '../../App.css';
import './Home.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';

import logo from '../../images/minimal-logo.jpg';

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <HeroSection />
      <Cards />
      <h1 className='main'></h1>
      <div className='background' style={{height: 'auto'}}>
        <h1>What We Do</h1>
        <p style={{margin: "25px"}}>We invest for the long term in the technology sector that not only promotes a better future and economic growth for everyone but also promise high leveraged returns.</p>
        <img className='img' src={logo} style={{width: '75vw'}} />
        <h2 style={{margin: "50px"}}>Investment</h2>
        <p style={{margin: "25px"}}>Investing in future technology products such as cryptocurrency sector, financial sector, and software sector not only helps our firm stay ahead of the curve but also realize high returns and support sustainable causes and promote economic growth for all.</p>
        <h2 style={{margin: "50px"}}>Research</h2>
        <p style={{margin: "25px"}}>Researching in investment products by utilizing data analytics with over a million data points and machine learning models can help us leverage risk and minimize losses while maximizing gains.</p>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
