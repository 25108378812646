import React, { Component } from 'react';
import { useEffect } from 'react';
import Typist from 'react-typist';
import Jump from 'react-reveal/Jump';
import Footer from '../Footer';
import '../../App.css';
import './Contact.css';

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <div className='background'>
        <h2 className='heading'><Typist><Typist.Delay ms={100} />Email Us At</Typist></h2>
        <h2>
          <Jump><i class="fas fa-envelope"></i></Jump>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='mailto: contact@capitalskydata.com'>contact@capitalskydata.com</a>
        </h2>
      </div>
      <h1 className='contact'></h1>
      <Footer/>
    </div>
  );
}
